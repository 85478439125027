<template>
  <v-container fluid>
    <v-row>
      <v-card width="100%">
        <base-header-information :title="headerTitle">
          <template v-slot:description>
           <!-- <p class="mb-2">
              Estes documentos foram elaborados de acordo com as informações
              prévias e com os valores declarados desde quando assumimos a
              responsabilidade contábil da empresa. Caso não tenha nos informado
              a respeito de alguma despesa que gostaria que estivesse presente
              nas declarações, pedimos para que nos retorne com os comprovantes
              para que possamos incorporar à documentação.
            </p>
            <p class="mt-1">
              Importante: As despesas precisam ter sido efetuadas em nome (CNPJ)
              da empresa. Se estiver tudo certo e não quiser informar mais
              nenhuma despesa, pode utilizar seu informe e guardar a DRE e o
              Balanço Patrimonial da sua empresa.
            </p>
          -->
          </template>
        </base-header-information>
        <v-container>
          <v-data-table :headers="headers" :items="all">
            <template v-slot:item.name="{ item }">
              {{ item.dateReference | moment('YYYY') }} -
              {{ item.name }}
            </template>
            <template v-slot:item.action="{ item }">
              <k-download :id="item.id"></k-download>
            </template>
            <template slot="no-data">
              <p class="blue--text py-6 text-h6">
                <v-icon color="blue">info</v-icon>Em breve você receberá um
                e-mail avisando que seus documentos estão disponíveis
              </p>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import kHeaderDate from '@src/components/widget/HeaderDate.vue'
import kDownload from '@src/components/widget/Download.vue'

export default {
  name: 'Contract',
  components: {
    kHeaderDate,
    kDownload,
  },
  computed: {
    ...mapState({
      all: (state) => state.company.annualDeclaration,
    }),
  },
  data() {
    return {
      headers: [
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'ações',
          align: 'right',
          value: 'action',
          sortable: false,
        },
      ],
      //headerTitle: 'Fechamento contábil',
      headerTitle: 'Informe de Rendimentos',
      headerDescription: `Estes documentos foram elaborados de acordo com as informações prévias e com os valores declarados desde quando assumimos a responsabilidade contábil da empresa.
      Caso não tenha nos informado a respeito de alguma despesa que gostaria que estivesse presente nas declarações, pedimos para que nos retorne com os comprovantes para que possamos incorporar à documentação.

      Importante: As despesas precisam ter sido efetuadas em nome (CNPJ) da empresa.
      Se estiver tudo certo e não quiser informar mais nenhuma despesa, pode utilizar seu informe e guardar a DRE e o Balanço Patrimonial da sua empresa.`,
    }
  },
  methods: {
    ...mapActions({
      companyGetAnnualDeclaration: 'company/getAnnualDeclaration',
    }),
  },
  mounted() {
    this.companyGetAnnualDeclaration()
  },
}
</script>
<style scoped></style>
